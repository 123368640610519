import React from 'react';

const FacetTranslator = facetValue => {
  const facetTranslator = {
    '_tag_content_details.actor': 'Actor',
    '_tag_content_details.character': 'Character',
    '_tag_content_details.episode': 'Episode',
    '_tag_content_details.genre': 'Genre',
    '_tag_content_details.network': 'Network',
    '_tag_content_details.series_name': 'Series',
    '_tag_retail_details.brand': 'Brand',
    '_tag_retail_details.category': 'Category',
    '_tag_retail_details.gender': 'Gender',
    '_tag_retail_details.product_name': 'Product',
    '_tag_retail_details.retailer': 'Retailer',
  };
  const [facet, value] = facetValue.split(':');

  if (!facet) return facetValue;
  if (!value) return facetValue;

  return `${facetTranslator[facet]}: ${value}`;
};

export default FacetTranslator;
