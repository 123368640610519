export default {
  App: {
    primarySelected: 'rgba(154, 80, 255, 0.6)',
    PrimarySelected: 'rgba(154, 80, 255, 0.6)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    'View BG': 'rgba(152, 153, 156, 0.25)',
    appGreen: 'rgb(0, 204, 154)',
    'App Green': 'rgb(0, 204, 154)',
    custom_rgb251_252_253: 'rgb(251, 252, 253)',
    'Custom Color': 'rgb(251, 252, 253)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    warning: 'rgb(212, 177, 6)',
    Warning: 'rgb(212, 177, 6)',
    socialOrange: 'rgb(255, 135, 67)',
    'Social Orange': 'rgb(255, 135, 67)',
    cardBorder: 'rgb(74, 74, 101)',
    'Card Border': 'rgb(74, 74, 101)',
    transparentWhite: 'rgba(255, 255, 255, 0.75)',
    TransparentWhite: 'rgba(255, 255, 255, 0.75)',
  },
  Brand: {
    surface: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    background: 'rgb(4, 5, 23)',
    Background: 'rgb(4, 5, 23)',
    primary: 'rgb(154, 80, 255)',
    Primary: 'rgb(154, 80, 255)',
    error: 'rgba(255, 69, 100, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    light: 'rgb(187, 195, 203)',
    Light: 'rgb(187, 195, 203)',
    medium: 'rgb(70, 78, 88)',
    Medium: 'rgb(70, 78, 88)',
    divider: 'rgba(234, 237, 242, 1)',
    Divider: 'rgba(234, 237, 242, 1)',
    strong: 'rgb(20, 19, 43)',
    Strong: 'rgb(20, 19, 43)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(59, 201, 234, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
  },
};
