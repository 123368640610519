import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import centToDollar from './global-functions/centToDollar';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import AdsManagementScreen from './screens/AdsManagementScreen';
import ApproveAdsScreen from './screens/ApproveAdsScreen';
import ApproveTagsScreen from './screens/ApproveTagsScreen';
import AuthLoginScreen from './screens/AuthLoginScreen';
import AuthSignupScreen from './screens/AuthSignupScreen';
import BalanceScreen from './screens/BalanceScreen';
import ComponentSidebarScreen from './screens/ComponentSidebarScreen';
import DashboardScreen from './screens/DashboardScreen';
import DiscoverScreen from './screens/DiscoverScreen';
import PushNotificationsScreen from './screens/PushNotificationsScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#040517',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        screenOptions={({ navigation }) => ({
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="AuthLoginScreen"
          component={AuthLoginScreen}
          options={({ navigation }) => ({
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="ComponentSidebarScreen"
          component={ComponentSidebarScreen}
          options={({ navigation }) => ({
            title: 'Sidebar',
          })}
        />
        <Stack.Screen
          name="AdsManagementScreen"
          component={AdsManagementScreen}
          options={({ navigation }) => ({
            title: 'Ads Management',
          })}
        />
        <Stack.Screen
          name="DashboardScreen"
          component={DashboardScreen}
          options={({ navigation }) => ({
            title: 'Dashboard',
          })}
        />
        <Stack.Screen
          name="AuthSignupScreen"
          component={AuthSignupScreen}
          options={({ navigation }) => ({
            title: 'Signup',
          })}
        />
        <Stack.Screen
          name="BalanceScreen"
          component={BalanceScreen}
          options={({ navigation }) => ({
            title: 'Balance',
          })}
        />
        <Stack.Screen
          name="ApproveAdsScreen"
          component={ApproveAdsScreen}
          options={({ navigation }) => ({
            title: 'Approve Ads',
          })}
        />
        <Stack.Screen
          name="ApproveTagsScreen"
          component={ApproveTagsScreen}
          options={({ navigation }) => ({
            title: 'Approve Tags',
          })}
        />
        <Stack.Screen
          name="PushNotificationsScreen"
          component={PushNotificationsScreen}
          options={({ navigation }) => ({
            title: 'Push Notifications',
          })}
        />
        <Stack.Screen
          name="DiscoverScreen"
          component={DiscoverScreen}
          options={({ navigation }) => ({
            title: 'Discover Screen',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
