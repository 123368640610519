import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import PromotionsEditorBlock from '../components/PromotionsEditorBlock';
import TrendingTableBlock from '../components/TrendingTableBlock';
import WhatsHotTableBlock from '../components/WhatsHotTableBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomComponents from '../custom-files/CustomComponents';
import * as Sidebar from '../custom-files/Sidebar';
import formatDate from '../global-functions/formatDate';
import getTableRowColor from '../global-functions/getTableRowColor';
import randomNumber from '../global-functions/randomNumber';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Surface,
  Swiper,
  SwiperItem,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const DiscoverScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [attachment_to_preview, setAttachment_to_preview] = React.useState({});
  const [currently_toggling_id, setCurrently_toggling_id] = React.useState({});
  const [is_moving, setIs_moving] = React.useState(false);
  const [seed, setSeed] = React.useState(0);
  const [showPreview, setShowPreview] = React.useState(true);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['MA_AUTH_TOKEN']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth({ flexDirection: 'row' }, dimensions.width)}
    >
      {/* Sidebar */}
      <Utils.CustomCodeErrorBoundary>
        <Sidebar.Menu
          currentScreen="discover"
          navigation={props.navigation}
          collapsed={Variables.SIDEBAR_MODE === 'collapsed'}
        />
      </Utils.CustomCodeErrorBoundary>
      <XanoAdminApi.FetchGetDiscoverDataGET>
        {({ loading, error, data, refetchGetDiscoverData }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, margin: 20 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['ScreenHeader'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['ScreenHeader'].style,
                    dimensions.width
                  )}
                >
                  {'Discover Screen Settings'}
                </Text>
                {/* Container */}
                <Surface
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      {
                        backgroundColor: palettes.Brand.Surface,
                        borderRadius: 20,
                        flex: 1,
                        flexDirection: 'row',
                        gap: 8,
                        padding: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* PreviewArea */}
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.Brand.Surface,
                          borderColor: theme.colors.text.strong,
                          borderRadius: 8,
                          borderWidth: 1,
                          marginRight: -10,
                          marginTop: -10,
                          padding: 6,
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          zIndex: 50,
                        },
                        dimensions.width
                      )}
                    >
                      <Pressable
                        onPress={() => {
                          try {
                            setShowPreview(!showPreview);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {showPreview ? 'Hide' : 'Show'}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                    {/* Preview */}
                    <>
                      {!showPreview ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: theme.colors.background.brand,
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors.text.strong,
                                },
                              ],
                              borderRadius: 12,
                              height: 844,
                              padding: 8,
                              width: 390,
                              zIndex: 0,
                            },
                            dimensions.width
                          )}
                        >
                          {/* ResponsiveWrapper */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flex: [
                                  { minWidth: Breakpoints.BigScreen, value: 1 },
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                ],
                                overflow: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'hidden',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'hidden',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Header */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  margin: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 8,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 8 },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center' },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={Images.LogoWhiteFull}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        height: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 150,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                            {/* Body */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  overflow: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'hidden',
                                  },
                                  paddingLeft: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 12,
                                  },
                                  paddingRight: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 12,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <XanoAdminApi.FetchGetDiscoverDataGET>
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetDiscoverData,
                                }) => {
                                  const fetchData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* WhatsHotView  */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: [
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 1.3,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 1.3,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value:
                                                    palettes.App[
                                                      'Custom Color'
                                                    ],
                                                },
                                                fontFamily: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value:
                                                    'SpaceGrotesk_600SemiBold',
                                                },
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 16,
                                                },
                                                marginBottom: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 8,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {"What's Hot Now"}
                                        </Text>
                                        <Swiper
                                          data={fetchData?.hot}
                                          dotActiveColor={
                                            theme.colors.branding.primary
                                          }
                                          dotColor={theme.colors.text.light}
                                          dotsTouchable={true}
                                          keyExtractor={(swiperData, index) =>
                                            swiperData?.id ??
                                            swiperData?.uuid ??
                                            index?.toString() ??
                                            JSON.stringify(swiperData)
                                          }
                                          listKey={'LXyNiDwS'}
                                          minDistanceForAction={0.2}
                                          minDistanceToCapture={5}
                                          renderItem={({ item, index }) => {
                                            const swiperData = item;
                                            return (
                                              <SwiperItem>
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flex: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 1,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value: 1,
                                                        },
                                                      ],
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Pressable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            if (
                                                              swiperData?.url
                                                            ) {
                                                              await WebBrowser.openBrowserAsync(
                                                                `${swiperData?.url}`
                                                              );
                                                            } else {
                                                            }
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        height: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: '100%',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <ImageBackground
                                                      resizeMode={'cover'}
                                                      {...GlobalStyles.ImageBackgroundStyles(
                                                        theme
                                                      )['Image Background']
                                                        .props}
                                                      source={{
                                                        uri: `${swiperData?.image?.url}`,
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.ImageBackgroundStyles(
                                                            theme
                                                          )['Image Background']
                                                            .style,
                                                          {
                                                            justifyContent: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value:
                                                                  'flex-end',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  'flex-end',
                                                              },
                                                            ],
                                                            paddingBottom: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 40,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 40,
                                                              },
                                                            ],
                                                            paddingLeft: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 15,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 15,
                                                              },
                                                            ],
                                                            paddingRight: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 15,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 15,
                                                              },
                                                            ],
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Title */}
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].props}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].style,
                                                            {
                                                              color: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value:
                                                                    palettes
                                                                      .App[
                                                                      'Custom Color'
                                                                    ],
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    palettes
                                                                      .App[
                                                                      'Custom Color'
                                                                    ],
                                                                },
                                                              ],
                                                              fontFamily: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value:
                                                                    'SpaceGrotesk_600SemiBold',
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    'SpaceGrotesk_600SemiBold',
                                                                },
                                                              ],
                                                              fontSize: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 16,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 16,
                                                                },
                                                              ],
                                                              textAlign: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value:
                                                                    'center',
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    'center',
                                                                },
                                                              ],
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {swiperData?.title}
                                                      </Text>
                                                    </ImageBackground>
                                                  </Pressable>
                                                </View>
                                              </SwiperItem>
                                            );
                                          }}
                                          vertical={false}
                                          {...GlobalStyles.SwiperStyles(theme)[
                                            'Swiper'
                                          ].props}
                                          loop={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.SwiperStyles(theme)[
                                                'Swiper'
                                              ].style,
                                              {
                                                borderRadius: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 5,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 5,
                                                  },
                                                ],
                                                height: '85%',
                                                overflow: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 'hidden',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 'hidden',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          timeout={5}
                                        />
                                      </View>
                                      {/* ExploreButtons */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            minHeight: [
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 225,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 225,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* SectionTitle */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                  },
                                                ],
                                                fontFamily: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value:
                                                      'SpaceGrotesk_600SemiBold',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      'SpaceGrotesk_600SemiBold',
                                                  },
                                                ],
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 16,
                                                  },
                                                ],
                                                marginBottom: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 8,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 8,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Explore The App'}
                                        </Text>
                                        <SimpleStyleFlatList
                                          data={fetchData?.promo_buttons}
                                          horizontal={false}
                                          inverted={false}
                                          keyExtractor={(listData, index) =>
                                            listData?.id ??
                                            listData?.uuid ??
                                            index?.toString() ??
                                            JSON.stringify(listData)
                                          }
                                          keyboardShouldPersistTaps={'never'}
                                          listKey={'eGasvFCS'}
                                          nestedScrollEnabled={false}
                                          numColumns={1}
                                          onEndReachedThreshold={0.5}
                                          renderItem={({ item, index }) => {
                                            const listData = item;
                                            return (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderRadius: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 5,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 5,
                                                      },
                                                    ],
                                                    height: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 90,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 90,
                                                      },
                                                    ],
                                                    overflow: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 'hidden',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 'hidden',
                                                      },
                                                    ],
                                                    width: 182,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Pressable
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: '100%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value: '100%',
                                                        },
                                                      ],
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: '100%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value: '100%',
                                                        },
                                                      ],
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Utils.CustomCodeErrorBoundary>
                                                    <CustomComponents.FadeCarousel
                                                      images={listData?.images}
                                                      containerStyle={{
                                                        flex: 1,
                                                      }}
                                                      fadeDuration={2000}
                                                      stillDuration={5000}
                                                      start={true}
                                                      height={
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                          ? 200
                                                          : dimensions.width >=
                                                            Breakpoints.Tablet
                                                          ? 130
                                                          : 90
                                                      }
                                                      // width={subtract(
                                                      //   getPercent(Math.min(dimensions.width, 1024), 50),
                                                      //   18
                                                      // )}
                                                      delay={
                                                        (Math.floor(
                                                          Math.random() * 5
                                                        ) +
                                                          1) *
                                                        1000
                                                      }
                                                    />
                                                  </Utils.CustomCodeErrorBoundary>
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'center',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 'center',
                                                          },
                                                        ],
                                                        height: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 90,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                        ],
                                                        justifyContent: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'center',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 'center',
                                                          },
                                                        ],
                                                        overflow: 'hidden',
                                                        width: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: '100%',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                        ],
                                                        zIndex: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 10,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 30,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value:
                                                                  palettes.App[
                                                                    'Custom Color'
                                                                  ],
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  palettes.App[
                                                                    'Custom Color'
                                                                  ],
                                                              },
                                                            ],
                                                            fontFamily: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value:
                                                                  'SpaceGrotesk_600SemiBold',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  'SpaceGrotesk_600SemiBold',
                                                              },
                                                            ],
                                                            fontSize: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 16,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 16,
                                                              },
                                                            ],
                                                            margin: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 16,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 16,
                                                              },
                                                            ],
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {listData?.promo_name}
                                                    </Text>
                                                  </View>
                                                </Pressable>
                                              </View>
                                            );
                                          }}
                                          showsHorizontalScrollIndicator={true}
                                          showsVerticalScrollIndicator={true}
                                          style={StyleSheet.applyWidth(
                                            {
                                              flex: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 1,
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 1,
                                                },
                                              ],
                                              flexDirection: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 'row',
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 'row',
                                                },
                                              ],
                                              flexWrap: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 'wrap',
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 'wrap',
                                                },
                                              ],
                                              gap: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 8,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 10,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 10,
                                                },
                                              ],
                                              justifyContent: {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 'flex-start',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                      {/* Trending */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: [
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 0.7,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 0.7,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* SectionTitle */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                  },
                                                ],
                                                fontFamily: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value:
                                                      'SpaceGrotesk_600SemiBold',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      'SpaceGrotesk_600SemiBold',
                                                  },
                                                ],
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 16,
                                                  },
                                                ],
                                                marginBottom: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 8,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 8,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Trending This Month'}
                                        </Text>
                                        <SimpleStyleFlatList
                                          data={fetchData?.trending}
                                          inverted={false}
                                          keyExtractor={(listData, index) =>
                                            listData?.id ??
                                            listData?.uuid ??
                                            index?.toString() ??
                                            JSON.stringify(listData)
                                          }
                                          keyboardShouldPersistTaps={'never'}
                                          listKey={'B0nDjJPQ'}
                                          nestedScrollEnabled={false}
                                          numColumns={1}
                                          onEndReachedThreshold={0.5}
                                          renderItem={({ item, index }) => {
                                            const listData = item;
                                            return (
                                              <Pressable
                                                onPress={() => {
                                                  const handler = async () => {
                                                    try {
                                                      await WebBrowser.openBrowserAsync(
                                                        `${listData?.url}`
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  };
                                                  handler();
                                                }}
                                              >
                                                <ImageBackground
                                                  resizeMode={'cover'}
                                                  {...GlobalStyles.ImageBackgroundStyles(
                                                    theme
                                                  )['Image Background'].props}
                                                  source={{
                                                    uri: `${listData?.image?.url}`,
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.ImageBackgroundStyles(
                                                        theme
                                                      )['Image Background']
                                                        .style,
                                                      {
                                                        alignItems: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'center',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 'center',
                                                          },
                                                        ],
                                                        borderRadius: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 5,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 5,
                                                          },
                                                        ],
                                                        height: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: '100%',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                        ],
                                                        justifyContent: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'flex-end',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 'flex-end',
                                                          },
                                                        ],
                                                        overflow: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'hidden',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 'hidden',
                                                          },
                                                        ],
                                                        width: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 135,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 135,
                                                          },
                                                        ],
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        {
                                                          color: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value:
                                                                palettes.App[
                                                                  'Custom Color'
                                                                ],
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                palettes.App[
                                                                  'Custom Color'
                                                                ],
                                                            },
                                                          ],
                                                          fontFamily: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value:
                                                                'SpaceGrotesk_600SemiBold',
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                'SpaceGrotesk_600SemiBold',
                                                            },
                                                          ],
                                                          margin: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value: 12,
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value: 12,
                                                            },
                                                          ],
                                                          textAlign: {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 'center',
                                                          },
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?.title}
                                                  </Text>
                                                </ImageBackground>
                                              </Pressable>
                                            );
                                          }}
                                          horizontal={true}
                                          showsHorizontalScrollIndicator={false}
                                          showsVerticalScrollIndicator={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              gap: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 8,
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 8,
                                                },
                                              ],
                                              height: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: '100%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: '100%',
                                                },
                                              ],
                                            },
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                    </>
                                  );
                                }}
                              </XanoAdminApi.FetchGetDiscoverDataGET>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  {/* FormArea */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: 1,
                        height: [
                          {
                            minWidth: Breakpoints.BigScreen,
                            value: dimensions.height - 100,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: dimensions.height - 100,
                          },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* WhatsHot */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['FormLabel'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'SpaceGrotesk_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"What's Hot Section\n"}
                      </Text>
                      {/* WhatsHot */}
                      <SimpleStyleScrollView
                        bounces={true}
                        horizontal={false}
                        keyboardShouldPersistTaps={'never'}
                        nestedScrollEnabled={false}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      >
                        <WhatsHotTableBlock
                          onUpdate={() => {
                            const handler = async () => {
                              try {
                                await refetchGetDiscoverData();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        />
                      </SimpleStyleScrollView>
                    </View>
                    {/* Promotions */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors.foreground.brand,
                          borderTopWidth: 1,
                          flex: 1,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Promotions */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['FormLabel'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'SpaceGrotesk_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Visible Promotions Section\n'}
                      </Text>
                      <PromotionsEditorBlock />
                    </View>
                    {/* Trending */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['FormLabel'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'SpaceGrotesk_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Trending Section\n'}
                      </Text>
                      {/* Trending */}
                      <SimpleStyleScrollView
                        bounces={true}
                        horizontal={false}
                        keyboardShouldPersistTaps={'never'}
                        nestedScrollEnabled={false}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      >
                        <TrendingTableBlock />
                      </SimpleStyleScrollView>
                    </View>
                  </View>
                </Surface>
              </View>
            </>
          );
        }}
      </XanoAdminApi.FetchGetDiscoverDataGET>
    </ScreenContainer>
  );
};

export default withTheme(DiscoverScreen);