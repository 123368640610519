import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Stripe from '../custom-files/Stripe';
import centToDollar from '../global-functions/centToDollar';
import refreshWallet from '../global-functions/refreshWallet';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  LinearGradient,
  NumberInput,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const WalletViewBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [centValue, setCentValue] = React.useState('');
  const [dollarValue, setDollarValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [noteValue, setNoteValue] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const totalBalance = (balance, reservedBalance) => {
    return parseInt(balance) + parseInt(reservedBalance);
  };

  const totalAmount = () => {
    return dollarValue * 100 + (parseInt(centValue) | 0);
  };

  const verifyForm = () => {
    const dollar = parseInt(dollarValue);

    if (isNaN(dollar)) {
      setErrorMessage('Please enter a valid number');
      return false;
    }

    if (dollar === 0) {
      setErrorMessage('Please enter a number higher than 0');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { flexDirection: { minWidth: Breakpoints.Desktop, value: 'row' } },
        dimensions.width
      )}
    >
      <XanoAdminApi.FetchGetBalanceGET
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'MA_BALANCE',
                value: fetchData?.balance,
              });
              setGlobalVariableValue({
                key: 'MA_RESERVED_BALANCE',
                value: fetchData?.reservedbalance,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        refresh={Constants['refresh_balance']}
      >
        {({ loading, error, data, refetchGetBalance }) => {
          const fetchData = data?.json;
          if (loading) {
            return (
              <>
                {/* Linear Gradient 2 */}
                <LinearGradient
                  color1={theme.colors.branding.primary}
                  endX={100}
                  endY={100}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'Linear Gradient'
                  ].props}
                  color2={palettes.Brand.Surface}
                  startX={100}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'Linear Gradient'
                      ].style,
                      { flexDirection: 'row', paddingBottom: 10 }
                    ),
                    dimensions.width
                  )}
                >
                  {/* TotalBalance */}
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {'Total Balance'}
                    </Text>
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                  </Surface>
                  {/* AvaliableBalance */}
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                        { marginLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {'Available Balance'}
                    </Text>
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                  </Surface>
                  {/* ReservedBalance */}
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                        { marginLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {'Reserved Balance'}
                    </Text>
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                  </Surface>
                </LinearGradient>
              </>
            );
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <LinearGradient
              color1={theme.colors.branding.primary}
              endX={100}
              endY={100}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                .props}
              color2={palettes.Brand.Surface}
              startX={100}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                    .style,
                  { flexDirection: 'row', paddingBottom: 10 }
                ),
                dimensions.width
              )}
            >
              {/* TotalBalance */}
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Total Balance'}
                </Text>
                {/* Total */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 20, marginTop: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'$ '}
                  {centToDollar(
                    totalBalance(fetchData?.balance, fetchData?.reservedbalance)
                  )}
                </Text>
              </Surface>
              {/* AvaliableBalance */}
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                    { marginLeft: 8 }
                  ),
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Available Balance'}
                </Text>
                {/* Total */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 20, marginTop: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'$ '}
                  {centToDollar(fetchData?.balance)}
                </Text>
              </Surface>
              {/* ReservedBalance */}
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                    { marginLeft: 8 }
                  ),
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Reserved Balance'}
                </Text>
                {/* Total */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 20, marginTop: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'$ '}
                  {centToDollar(fetchData?.reservedbalance)}
                </Text>
              </Surface>
              {/* Actions */}
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['BalanceCard'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['BalanceCard'].style,
                    { justifyContent: 'center', marginLeft: 8 }
                  ),
                  dimensions.width
                )}
              >
                {/* AddCreditBtn */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Ionicons/add-circle-outline'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.Brand.Surface,
                        color: theme.colors.branding.primary,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Add Credit'}
                />
              </Surface>
            </LinearGradient>
          );
        }}
      </XanoAdminApi.FetchGetBalanceGET>
      {/* AddBalanceModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={showModal}
      >
        <BlurView
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={60}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center' }
            ),
            dimensions.width
          )}
          tint={'dark'}
        >
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={3}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  backgroundColor: palettes.Brand.Surface,
                  borderColor: theme.colors.border.brand,
                  borderRadius: 16,
                  borderWidth: 1,
                  minWidth: 300,
                  padding: 16,
                }
              ),
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  setShowModal(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/closecircleo'}
              style={StyleSheet.applyWidth(
                {
                  position: {
                    minWidth: Breakpoints.Desktop,
                    value: 'absolute',
                  },
                  right: { minWidth: Breakpoints.Desktop, value: 16 },
                  top: { minWidth: Breakpoints.Desktop, value: 8 },
                },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: 'SpaceGrotesk_700Bold',
                    fontSize: 22,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Add Credit'}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { marginTop: 12 }
                ),
                dimensions.width
              )}
            >
              {'Please use this form to add credits to your wallet'}
            </Text>
            {/* Row */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end', flexDirection: 'row', marginTop: 24 },
                dimensions.width
              )}
            >
              {/* DollarView */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: 0.85 },
                      { minWidth: Breakpoints.Desktop, value: 0.85 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* DolarInput */}
                <NumberInput
                  changeTextDelay={500}
                  onChangeText={newDolarInputValue => {
                    try {
                      setDollarValue(newDolarInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                    .props}
                  placeholder={'Enter an amount'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.NumberInputStyles(theme)['Number Input']
                        .style,
                      { borderWidth: 1 }
                    ),
                    dimensions.width
                  )}
                  value={dollarValue}
                />
              </View>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginLeft: 6, marginRight: 6 }
                  ),
                  dimensions.width
                )}
              >
                {'.'}
              </Text>
              {/* CentView */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: [
                      { minWidth: Breakpoints.Desktop, value: 0.15 },
                      { minWidth: Breakpoints.Mobile, value: 0.15 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* CentValue */}
                <NumberInput
                  changeTextDelay={500}
                  onChangeText={newCentValueValue => {
                    try {
                      setCentValue(newCentValueValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                    .props}
                  maxLength={2}
                  placeholder={'00'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.NumberInputStyles(theme)['Number Input']
                        .style,
                      { borderWidth: 1 }
                    ),
                    dimensions.width
                  )}
                  value={centValue}
                />
              </View>
            </View>
            {/* NoteInput */}
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              multiline={true}
              numberOfLines={4}
              onChangeText={newNoteInputValue => {
                try {
                  setNoteValue(newNoteInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
              placeholder={'Enter a note'}
              placeholderTextColor={theme.colors.text.light}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                  { marginTop: 12 }
                ),
                dimensions.width
              )}
              value={noteValue}
            />
            {/* ErrorMessages */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.background.danger }
                ),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
            {/* AddBalanceButton */}
            <Utils.CustomCodeErrorBoundary>
              <Stripe.PaymentButton
                theme={props.theme}
                navigation={props.navigation}
                amount={totalAmount()}
                description={noteValue}
                setErrorMessage={setErrorMessage}
                verify={verifyForm}
                callback={() => {
                  setShowModal(false);
                  refreshWallet(setGlobalVariableValue);
                }}
              />
            </Utils.CustomCodeErrorBoundary>
          </Surface>
        </BlurView>
      </Modal>
    </View>
  );
};

export default withTheme(WalletViewBlock);
