import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatDate from '../global-functions/formatDate';
import getTableRowColor from '../global-functions/getTableRowColor';
import randomNumber from '../global-functions/randomNumber';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const PromotionsEditorBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [currently_deleting_id, setCurrently_deleting_id] = React.useState(0);
  const [currently_toggling_id, setCurrently_toggling_id] = React.useState({});
  const [currently_uploading_id, setCurrently_uploading_id] = React.useState(0);
  const [item_to_delete, setItem_to_delete] = React.useState(null);
  const xanoAdminUploadPromotionImagePOST =
    XanoAdminApi.useUploadPromotionImagePOST();
  const xanoAdminDeletePromotionImageDELETE =
    XanoAdminApi.useDeletePromotionImageDELETE();

  return (
    <XanoAdminApi.FetchGetPromotionsGET>
      {({ loading, error, data, refetchGetPromotions }) => {
        const fetchData = data?.json;
        if (loading) {
          return <ActivityIndicator />;
        }

        if (error || data?.status < 200 || data?.status >= 300) {
          return <ActivityIndicator />;
        }

        return (
          <>
            <SimpleStyleFlatList
              data={fetchData}
              horizontal={false}
              inverted={false}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'SUGtV4jD'}
              nestedScrollEnabled={false}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getTableRowColor(index),
                        flexDirection: 'row',
                        height: 58,
                      },
                      dimensions.width
                    )}
                  >
                    {/* OrderView */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* UpButton */}
                      <IconButton
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setCurrently_toggling_id(listData?.id);
                              (
                                await XanoAdminApi.movePromotionGET(Constants, {
                                  direction: 'up',
                                  promotion_id: listData?.id,
                                })
                              )?.json;
                              setCurrently_toggling_id(null);
                              await refetchGetPromotions();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        size={32}
                        icon={'Feather/arrow-up'}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: 15 },
                          dimensions.width
                        )}
                      >
                        <>
                          {!(currently_toggling_id !== listData?.id) ? null : (
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {listData?.order}
                            </Text>
                          )}
                        </>
                        <>
                          {!(currently_toggling_id === listData?.id) ? null : (
                            <ActivityIndicator
                              animating={true}
                              hidesWhenStopped={true}
                              size={'small'}
                              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].props}
                              color={theme.colors.branding.primary}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  { height: 24, width: 24 }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>
                      {/* DownButton */}
                      <IconButton
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setCurrently_toggling_id(listData?.id);
                              (
                                await XanoAdminApi.movePromotionGET(Constants, {
                                  direction: 'down',
                                  promotion_id: listData?.id,
                                })
                              )?.json;
                              setCurrently_toggling_id(null);
                              await refetchGetPromotions();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        size={32}
                        icon={'Feather/arrow-down'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {listData?.promo_name}
                      </Text>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 3, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* ImagesList */}
                      <SimpleStyleFlatList
                        data={listData?.images}
                        inverted={false}
                        keyExtractor={(imagesListData, index) =>
                          imagesListData?.id ??
                          imagesListData?.uuid ??
                          index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={JSON.stringify(listData?.images)}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const imagesListData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'center',
                                  },
                                  borderColor: theme.colors.foreground.brand,
                                  borderRadius: 6,
                                  borderWidth: 1,
                                  justifyContent: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'center',
                                  },
                                  overflow: 'hidden',
                                },
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  try {
                                    setItem_to_delete(imagesListData);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{
                                    uri: `${imagesListData?.cloudinary?.optimized_url}`,
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      { height: 50, width: 50 }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </Pressable>
                            </View>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        horizontal={true}
                        style={StyleSheet.applyWidth(
                          { gap: 4 },
                          dimensions.width
                        )}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Mobile, value: 'center' },
                            ],
                            backgroundColor: palettes.Brand.Surface,
                            borderColor: theme.colors.foreground.brand,
                            borderRadius: 6,
                            borderWidth: 1,
                            height: 52,
                            justifyContent: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Mobile, value: 'center' },
                            ],
                            marginLeft: 4,
                            overflow: 'hidden',
                            width: 52,
                          },
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const selectedImage = await openImagePickerUtil(
                                  {
                                    mediaTypes: 'Images',
                                    allowsEditing: false,
                                    quality: 0.2,
                                    allowsMultipleSelection: false,
                                    permissionErrorMessage:
                                      'Sorry, we need media library permissions to make this work.',
                                    showAlertOnPermissionError: true,
                                  }
                                );

                                setCurrently_uploading_id(listData?.id);
                                (
                                  await xanoAdminUploadPromotionImagePOST.mutateAsync(
                                    {
                                      image_data: selectedImage,
                                      promotions_id: listData?.id,
                                    }
                                  )
                                )?.json;
                                setCurrently_uploading_id(null);
                                await waitUtil({ milliseconds: 1000 });
                                await refetchGetPromotions();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <>
                            {!(
                              currently_uploading_id !== listData?.id
                            ) ? null : (
                              <Icon
                                size={24}
                                name={'MaterialIcons/add-circle-outline'}
                              />
                            )}
                          </>
                          <>
                            {!(
                              currently_uploading_id === listData?.id
                            ) ? null : (
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </Pressable>
                      </View>
                    </View>
                  </View>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            />
            {/* DeleteModal */}
            <Modal
              supportedOrientations={['portrait', 'landscape']}
              animationType={'fade'}
              presentationStyle={'fullScreen'}
              transparent={true}
              visible={item_to_delete}
            >
              <BlurView
                tint={'default'}
                {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
                intensity={20}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 16,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* ModalContainer */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.Brand.Surface,
                      borderColor: theme.colors.foreground.brand,
                      borderRadius: 16,
                      borderWidth: 1,
                      maxWidth: 400,
                      width: '90%',
                    },
                    dimensions.width
                  )}
                >
                  {/* ModalHeader */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderColor: theme.colors.foreground.brand,
                        flexDirection: 'row',
                        marginBottom: 8,
                        padding: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { flex: 1, fontFamily: 'SpaceGrotesk_700Bold' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Are You Sure ?'}
                    </Text>
                    <IconButton
                      onPress={() => {
                        try {
                          setItem_to_delete(null);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      icon={'AntDesign/closecircleo'}
                    />
                  </View>
                  {/* ModalBody */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, padding: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { marginBottom: 16, marginLeft: 8 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'You are about to delete this promo image ('}
                      {item_to_delete?.id}
                      {'). Are you sure that you want to continue?'}
                    </Text>
                  </View>
                  {/* ModalFooter */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderRadius: 9,
                        flexDirection: 'row',
                        gap: 8,
                        justifyContent: 'space-around',
                        padding: 16,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* CancelButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setItem_to_delete(null);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['OutlineButton']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* DeleteButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setCurrently_deleting_id(item_to_delete?.id);
                              (
                                await xanoAdminDeletePromotionImageDELETE.mutateAsync(
                                  { promotionImagesId: item_to_delete?.id }
                                )
                              )?.json;
                              setCurrently_deleting_id(null);
                              setItem_to_delete(null);
                              await refetchGetPromotions();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        disabled={currently_deleting_id}
                        loading={currently_deleting_id}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            {
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors.background.danger,
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors.background.danger,
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Yes, Delete'}
                      />
                    </View>
                  </View>
                </View>
              </BlurView>
            </Modal>
          </>
        );
      }}
    </XanoAdminApi.FetchGetPromotionsGET>
  );
};

export default withTheme(PromotionsEditorBlock);
