import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import centToDollar from '../global-functions/centToDollar';
import toDateString from '../global-functions/toDateString';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Circle, Icon, TableCell, TableRow, withTheme } from '@draftbit/ui';
import { Text } from 'react-native';

const BalanceTableRowBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const totalBalance = (balance, reservedBalance) => {
    return parseInt(balance) + parseInt(reservedBalance);
  };

  return (
    <TableRow
      drawEndBorder={false}
      drawTopBorder={false}
      isTableHeader={false}
      drawBottomBorder={false}
      drawStartBorder={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: props.backgroundColor ?? '#FFFFFF' },
        dimensions.width
      )}
    >
      {/* TypeCell */}
      <TableCell
        drawBottomBorder={false}
        drawStartBorder={false}
        drawTopBorder={false}
        {...GlobalStyles.TableCellStyles(theme)['Table Cell'].props}
        drawEndBorder={false}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.TableCellStyles(theme)['Table Cell'].style,
            { maxWidth: 75 }
          ),
          dimensions.width
        )}
      >
        <Circle
          {...GlobalStyles.CircleStyles(theme)['Circle'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.CircleStyles(theme)['Circle'].style,
              {
                backgroundColor: theme.colors.border.brand,
                height: 40,
                width: 40,
              }
            ),
            dimensions.width
          )}
        >
          {/* CreditIcon */}
          <>
            {!((props.type ?? 'credit') === 'credit') ? null : (
              <Icon
                color={palettes.App['App Green']}
                name={'AntDesign/caretdown'}
                size={20}
              />
            )}
          </>
          {/* DebitIcon */}
          <>
            {!((props.type ?? 'credit') === 'debit') ? null : (
              <Icon
                color={theme.colors.background.danger}
                name={'AntDesign/caretup'}
                size={20}
              />
            )}
          </>
          {/* RefundIcon */}
          <>
            {!((props.type ?? 'credit') === 'refund') ? null : (
              <Icon
                color={palettes.App['App Green']}
                name={'AntDesign/caretdown'}
                size={20}
              />
            )}
          </>
          {/* HoldIcon */}
          <>
            {!((props.type ?? 'credit') === 'hold') ? null : (
              <Icon
                color={theme.colors.background.danger}
                name={'AntDesign/caretup'}
                size={20}
              />
            )}
          </>
        </Circle>
      </TableCell>
      {/* DateCell */}
      <TableCell
        drawBottomBorder={false}
        drawStartBorder={false}
        drawTopBorder={false}
        {...GlobalStyles.TableCellStyles(theme)['Table Cell'].props}
        drawEndBorder={false}
        style={StyleSheet.applyWidth(
          GlobalStyles.TableCellStyles(theme)['Table Cell'].style,
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {toDateString(props.date ?? 1701692455341)}
        </Text>
      </TableCell>
      {/* Description */}
      <TableCell
        drawBottomBorder={false}
        drawStartBorder={false}
        drawTopBorder={false}
        {...GlobalStyles.TableCellStyles(theme)['Table Cell'].props}
        drawEndBorder={false}
        style={StyleSheet.applyWidth(
          GlobalStyles.TableCellStyles(theme)['Table Cell'].style,
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {props.description ?? 'Initial Balance'}
        </Text>
      </TableCell>
      {/* AmountCell */}
      <TableCell
        drawBottomBorder={false}
        drawStartBorder={false}
        drawTopBorder={false}
        {...GlobalStyles.TableCellStyles(theme)['Table Cell'].props}
        drawEndBorder={false}
        style={StyleSheet.applyWidth(
          GlobalStyles.TableCellStyles(theme)['Table Cell'].style,
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {'$ '}
          {centToDollar(props.amount ?? 1000)}
        </Text>
      </TableCell>
      {/* Details */}
      <TableCell
        drawBottomBorder={false}
        drawStartBorder={false}
        drawTopBorder={false}
        {...GlobalStyles.TableCellStyles(theme)['Table Cell'].props}
        drawEndBorder={false}
        style={StyleSheet.applyWidth(
          GlobalStyles.TableCellStyles(theme)['Table Cell'].style,
          dimensions.width
        )}
      >
        {/* CompletedText */}
        <>
          {!((props.status ?? 'pending') === 'completed') ? null : (
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: palettes.App['App Green'] }
                ),
                dimensions.width
              )}
            >
              {'Completed'}
            </Text>
          )}
        </>
        {/* PendingText */}
        <>
          {!((props.status ?? 'pending') === 'pending') ? null : (
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: palettes.App.Warning }
                ),
                dimensions.width
              )}
            >
              {'Pending'}
            </Text>
          )}
        </>
        {/* FailedText */}
        <>
          {!((props.status ?? 'pending') === 'failed') ? null : (
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.background.danger }
                ),
                dimensions.width
              )}
            >
              {'Failed'}
            </Text>
          )}
        </>
      </TableCell>
    </TableRow>
  );
};

export default withTheme(BalanceTableRowBlock);
