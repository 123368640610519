import React from 'react';

const refreshWallet = setGlobalVariableValue => {
  setGlobalVariableValue({
    key: 'refresh_balance',
    value: Math.floor(Math.random() * 9000000) + 1000000,
  });
};

export default refreshWallet;
