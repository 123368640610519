import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as UnsplashApi from '../apis/UnsplashApi.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { H2 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const AuthLoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const validateForm = () => {
    if (emailValue === '') {
      setErrorMessage('Please enter your email');
      return false;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    if (passwordValue === '') {
      setErrorMessage('Please enter your password');
      return false;
    }

    return true;
  };

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Container */}
      <Surface
        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
        elevation={1}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            {
              borderRadius: 16,
              flexDirection: 'row',
              height: '90%',
              marginLeft: '5%',
              marginTop: '5%',
              overflow: 'hidden',
              width: '90%',
            }
          ),
          dimensions.width
        )}
      >
        {/* LeftView */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.Brand.Surface,
                  flex: 1,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <UnsplashApi.FetchGetRandomPhotoGET
                clientId={'L5Ami1Q3-LG1fQW4QZIBYKHDHscWdfdRJmhx0ZpnDh0'}
                orientation={'landscape'}
                query={'fashion'}
              >
                {({ loading, error, data, refetchGetRandomPhoto }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <ImageBackground
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ].props}
                      source={{ uri: `${fetchData?.urls?.regular}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageBackgroundStyles(theme)[
                            'Image Background'
                          ].style,
                          {
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <BlurView
                        intensity={50}
                        tint={'default'}
                        {...GlobalStyles.BlurViewStyles(theme)['Blur View']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.BlurViewStyles(theme)['Blur View']
                              .style,
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Logo */}
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={Images.LogoPurpleFull}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              {
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 150,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 350,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <LinearGradient
                          color1={theme.colors.branding.primary}
                          color2={theme.colors.branding.secondary}
                          endX={100}
                          endY={100}
                          startX={0}
                          startY={0}
                          {...GlobalStyles.LinearGradientStyles(theme)[
                            'Linear Gradient'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinearGradientStyles(theme)[
                                'Linear Gradient'
                              ].style,
                              {
                                alignItems: 'center',
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 16,
                                },
                                flex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 200,
                                },
                                justifyContent: 'center',
                                opacity: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0.5,
                                },
                                position: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'absolute',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 380,
                                },
                                zIndex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </BlurView>
                    </ImageBackground>
                  );
                }}
              </UnsplashApi.FetchGetRandomPhotoGET>
            </View>
          )}
        </>
        {/* RightView */}
        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: palettes.Brand.Surface, flex: 1 },
            dimensions.width
          )}
        >
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      position: 'absolute',
                      top: 20,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images.LogoPurpleFull}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 150, width: 350 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* Form */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <H2
                selectable={false}
                {...GlobalStyles.H2Styles(theme)['H2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.H2Styles(theme)['H2'].style, {
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Admin Panel Login'}
              </H2>
              {/* Email */}
              <View>
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Email'}
                </Text>
                {/* EmailInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newEmailInputValue => {
                    try {
                      setEmailValue(newEmailInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Enter Email'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={emailValue}
                />
              </View>
              {/* Password */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Password'}
                </Text>
                {/* PasswordInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newPasswordInputValue => {
                    try {
                      setPasswordValue(newPasswordInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  placeholder={'Enter Password'}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={passwordValue}
                />
              </View>
              {/* ErrorText */}
              <>
                {!errorMessage ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.danger,
                          },
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                )}
              </>
              {/* LoginButton */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (!validateForm()) {
                        return;
                      }
                      setIsLoading(true);
                      const loginResult = (
                        await XanoAdminApi.loginPOST(Constants, {
                          email: emailValue,
                          password: passwordValue,
                        })
                      )?.json;
                      setIsLoading(false);
                      setErrorMessage(loginResult?.message);
                      if (loginResult?.message) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'MA_AUTH_TOKEN',
                        value: loginResult?.auth_token,
                      });
                      setGlobalVariableValue({
                        key: 'MA_USER_DATA',
                        value: loginResult?.user,
                      });
                      navigation.navigate('DashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                disabled={isLoading}
                loading={isLoading}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    { marginTop: 15 }
                  ),
                  dimensions.width
                )}
                title={'Login'}
              />
            </View>
          </SimpleStyleKeyboardAwareScrollView>
        </View>
      </Surface>
    </ScreenContainer>
  );
};

export default withTheme(AuthLoginScreen);
