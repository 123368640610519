import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Sidebar from '../custom-files/Sidebar';
import centToDollar from '../global-functions/centToDollar';
import getTableRowColor from '../global-functions/getTableRowColor';
import transformArrWithIndex from '../global-functions/transformArrWithIndex';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Checkbox,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  Surface,
  Table,
  TableCell,
  TableRow,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ApproveAdsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [attachment_to_preview, setAttachment_to_preview] =
    React.useState(null);
  const [currently_toggling_id, setCurrently_toggling_id] =
    React.useState(null);
  const displayAtLabel = val => {
    return val === 'homepage'
      ? 'Homepage'
      : val === 'search_results'
      ? 'Search R.'
      : val === 'related_tags'
      ? 'Related'
      : val === 'user_profiles'
      ? 'Profiles'
      : '';
  };

  const timeStampToReadable = timestamp => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const showApproveButton = (id, is_active) => {
    if (is_active) return false;

    if (currently_toggling_id === id) return false;

    return true;
  };
  const xanoAdminToggleApproveGET = XanoAdminApi.useToggleApproveGET();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['MA_AUTH_TOKEN']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth({ flexDirection: 'row' }, dimensions.width)}
    >
      {/* Sidebar */}
      <Utils.CustomCodeErrorBoundary>
        <Sidebar.Menu
          currentScreen="approve_ads"
          navigation={props.navigation}
          collapsed={Variables.SIDEBAR_MODE === 'collapsed'}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth({ flex: 1, margin: 20 }, dimensions.width)}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['ScreenHeader'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['ScreenHeader'].style,
            dimensions.width
          )}
        >
          {'Approve Ads'}
        </Text>
        {/* Container */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              {
                backgroundColor: palettes.Brand.Surface,
                borderRadius: 20,
                flex: 1,
                overflow: 'hidden',
              }
            ),
            dimensions.width
          )}
        >
          <XanoAdminApi.FetchGetUnapprovedAdsGET>
            {({ loading, error, data, refetchGetUnapprovedAds }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <Table
                  borderColor={theme.colors.border.brand}
                  borderStyle={'solid'}
                  cellHorizontalPadding={10}
                  cellVerticalPadding={10}
                  data={transformArrWithIndex(fetchData)}
                  drawBottomBorder={false}
                  drawEndBorder={false}
                  drawStartBorder={false}
                  keyExtractor={(tableData, index) =>
                    tableData?.id ?? tableData?.uuid ?? index.toString()
                  }
                  listKey={'VFaYp1lZ'}
                  renderItem={({ item, index }) => {
                    const tableData = item;
                    return (
                      <>
                        {/* Header */}
                        <TableRow
                          drawEndBorder={false}
                          drawTopBorder={false}
                          borderStyle={'solid'}
                          borderWidth={0}
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          isTableHeader={true}
                        >
                          {/* IdCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            borderWidth={0}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                { maxWidth: 35, minWidth: 35 }
                              ),
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'ID'}
                            </Text>
                          </TableCell>
                          {/* NameCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Name'}
                            </Text>
                          </TableCell>
                          {/* DateCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Start Date'}
                            </Text>
                          </TableCell>
                          {/* CreatorCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Creator'}
                            </Text>
                          </TableCell>
                          {/* IsActiveCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                { maxWidth: 65, minWidth: 65 }
                              ),
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Active'}
                            </Text>
                          </TableCell>
                          {/* BudgetCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Remaining Budget'}
                            </Text>
                          </TableCell>
                          {/* KeywordsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Keywords'}
                            </Text>
                          </TableCell>
                          {/* DisplayAtCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Display At'}
                            </Text>
                          </TableCell>
                          {/* AttachmentsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Attachments'}
                            </Text>
                          </TableCell>
                          {/* ActionsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'TableHeaderCell'
                            ].props}
                            borderStyle={'solid'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Actions'}
                            </Text>
                          </TableCell>
                        </TableRow>
                        {/* BodyRow */}
                        <TableRow
                          drawEndBorder={false}
                          drawTopBorder={false}
                          isTableHeader={false}
                          borderStyle={'dashed'}
                          borderWidth={0}
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Desktop,
                                value: getTableRowColor(tableData?.rowIndex),
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* IdCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                { maxWidth: 35, minWidth: 35 }
                              ),
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {tableData?.id}
                            </Text>
                          </TableCell>
                          {/* NameCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)['Table Cell']
                                .style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {tableData?.ad_name}
                            </Text>
                          </TableCell>
                          {/* DateCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)['Table Cell']
                                .style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {timeStampToReadable(tableData?.start_date)}
                            </Text>
                          </TableCell>
                          {/* Creator */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)['Table Cell']
                                .style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {tableData?._creator?.name +
                                ' (' +
                                tableData?._creator?.handle +
                                ')'}
                            </Text>
                          </TableCell>
                          {/* IsActiveCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                { maxWidth: 65, minWidth: 65 }
                              ),
                              dimensions.width
                            )}
                          >
                            <Checkbox
                              defaultValue={tableData?.is_active}
                              disabled={true}
                            />
                          </TableCell>
                          {/* BudgetCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)['Table Cell']
                                .style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {'$'}
                              {centToDollar(tableData?.remaining_budget)}
                              {' / $'}
                              {centToDollar(tableData?.budget)}
                            </Text>
                          </TableCell>
                          {/* KeywordsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            cellHorizontalPadding={0}
                            cellVerticalPadding={1}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                { height: 100 }
                              ),
                              dimensions.width
                            )}
                          >
                            <FlatList
                              data={tableData?._ads_keywords_of_ads}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={JSON.stringify(
                                tableData?._ads_keywords_of_ads
                              )}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'* '}
                                    {listData?.keyword}
                                  </Text>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </TableCell>
                          {/* DisplayAtCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                { height: 100 }
                              ),
                              dimensions.width
                            )}
                          >
                            <FlatList
                              data={tableData?.displayed_at}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={JSON.stringify(tableData?.displayed_at)}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <>
                                    {!listData ? null : (
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {'* '}
                                        {displayAtLabel(listData)}
                                      </Text>
                                    )}
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </TableCell>
                          {/* AttachmentsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                {
                                  maxHeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 90,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <FlatList
                              data={tableData?._ad_attachments}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={JSON.stringify(
                                tableData?._ad_attachments
                              )}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        setAttachment_to_preview(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'center',
                                          },
                                          borderColor: {
                                            minWidth: Breakpoints.Desktop,
                                            value: theme.colors.text.light,
                                          },
                                          borderRadius: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 6,
                                          },
                                          borderWidth: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 1,
                                          },
                                          justifyContent: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'center',
                                          },
                                          marginRight: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 4,
                                          },
                                          overflow: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'hidden',
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.ad_attachment?.type ===
                                          'image'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            {...GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].props}
                                            source={{
                                              uri: `${listData?.ad_attachment?.url}?tpl=small`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ].style,
                                                {
                                                  height: {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 50,
                                                  },
                                                  width: {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 50,
                                                  },
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.ad_attachment?.type ===
                                          'video'
                                        ) ? null : (
                                          <Icon
                                            name={'Ionicons/videocam'}
                                            size={40}
                                            style={StyleSheet.applyWidth(
                                              {
                                                marginBottom: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 5,
                                                },
                                                marginLeft: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 5,
                                                },
                                                marginRight: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 5,
                                                },
                                                marginTop: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 5,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  </Pressable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              horizontal={true}
                            />
                          </TableCell>
                          {/* ActionsCell */}
                          <TableCell
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            drawTopBorder={false}
                            {...GlobalStyles.TableCellStyles(theme)[
                              'Table Cell'
                            ].props}
                            borderStyle={'dashed'}
                            drawEndBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableCellStyles(theme)['Table Cell']
                                .style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'center',
                                  },
                                  flexDirection: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'row',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* StopButton */}
                              <>
                                {!tableData?.is_approved ? null : (
                                  <IconButton
                                    color={theme.colors.branding.primary}
                                    icon={'Feather/stop-circle'}
                                    size={28}
                                  />
                                )}
                              </>
                              {/* ActivateButton */}
                              <>
                                {!showApproveButton(
                                  tableData?.id,
                                  tableData?.is_approved
                                ) ? null : (
                                  <IconButton
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setCurrently_toggling_id(
                                            tableData?.id
                                          );
                                          (
                                            await xanoAdminToggleApproveGET.mutateAsync(
                                              { ads_id: tableData?.id }
                                            )
                                          )?.json;
                                          setCurrently_toggling_id(null);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    color={theme.colors.branding.primary}
                                    icon={'Ionicons/checkmark-circle-outline'}
                                    size={28}
                                  />
                                )}
                              </>
                              <>
                                {!(
                                  currently_toggling_id === tableData?.id
                                ) ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    {...GlobalStyles.ActivityIndicatorStyles(
                                      theme
                                    )['Activity Indicator'].props}
                                    color={theme.colors.branding.primary}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ActivityIndicatorStyles(
                                          theme
                                        )['Activity Indicator'].style,
                                        {
                                          marginLeft: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 0,
                                          },
                                          marginTop: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 0,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }}
                  showsVerticalScrollIndicator={true}
                  {...GlobalStyles.TableStyles(theme)['Table'].props}
                  borderWidth={0}
                  drawTopBorder={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TableStyles(theme)['Table'].style,
                    dimensions.width
                  )}
                />
              );
            }}
          </XanoAdminApi.FetchGetUnapprovedAdsGET>
        </Surface>
      </View>
      {/* AttachmentPreviewModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={attachment_to_preview}
      >
        <BlurView
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={20}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              {
                alignItems: { minWidth: Breakpoints.Desktop, value: 'center' },
                justifyContent: {
                  minWidth: Breakpoints.Desktop,
                  value: 'center',
                },
                padding: { minWidth: Breakpoints.Desktop, value: 16 },
              }
            ),
            dimensions.width
          )}
        >
          {/* ModalContainer */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Desktop,
                  value: palettes.Brand.Surface,
                },
                borderColor: {
                  minWidth: Breakpoints.Desktop,
                  value: theme.colors.text.light,
                },
                borderRadius: { minWidth: Breakpoints.Desktop, value: 16 },
                borderWidth: { minWidth: Breakpoints.Desktop, value: 1 },
                maxWidth: { minWidth: Breakpoints.Desktop, value: 800 },
                width: { minWidth: Breakpoints.Desktop, value: '90%' },
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Desktop,
                    value: 'center',
                  },
                  borderBottomWidth: {
                    minWidth: Breakpoints.Desktop,
                    value: 1,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Desktop,
                    value: theme.colors.text.light,
                  },
                  flexDirection: {
                    minWidth: Breakpoints.Desktop,
                    value: 'row',
                  },
                  marginBottom: { minWidth: Breakpoints.Desktop, value: 8 },
                  padding: { minWidth: Breakpoints.Desktop, value: 16 },
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      flex: { minWidth: Breakpoints.Desktop, value: 1 },
                      fontFamily: {
                        minWidth: Breakpoints.Desktop,
                        value: 'SpaceGrotesk_600SemiBold',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Preview Attachment'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setAttachment_to_preview(null);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/closecircleo'}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: { minWidth: Breakpoints.Desktop, value: 1 },
                  flexGrow: { minWidth: Breakpoints.Desktop, value: 1 },
                  flexShrink: { minWidth: Breakpoints.Desktop, value: 0 },
                  padding: { minWidth: Breakpoints.Desktop, value: 16 },
                },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 8 },
                      marginLeft: { minWidth: Breakpoints.Desktop, value: 16 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Attachment Asset :'}
              </Text>
              <>
                {!(
                  attachment_to_preview?.ad_attachment?.type === 'image'
                ) ? null : (
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={{
                      uri: `${attachment_to_preview?.ad_attachment?.url}`,
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Desktop, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta
                                  .height,
                            },
                          ],
                          maxHeight: {
                            minWidth: Breakpoints.Desktop,
                            value: 768,
                          },
                          maxWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 768,
                          },
                          width: [
                            { minWidth: Breakpoints.Desktop, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.width,
                            },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              <>
                {!(
                  attachment_to_preview?.ad_attachment?.type === 'video'
                ) ? null : (
                  <VideoPlayer
                    isLooping={false}
                    posterResizeMode={'cover'}
                    rate={1}
                    resizeMode={'cover'}
                    shouldPlay={false}
                    useNativeControls={true}
                    usePoster={false}
                    volume={0.5}
                    {...GlobalStyles.VideoPlayerStyles(theme)['Video'].props}
                    isMuted={true}
                    source={{
                      uri: `${attachment_to_preview?.ad_attachment?.url}`,
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.VideoPlayerStyles(theme)['Video'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Desktop, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.video
                                  .postHeight,
                            },
                          ],
                          maxHeight: {
                            minWidth: Breakpoints.Desktop,
                            value: 768,
                          },
                          maxWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 768,
                          },
                          minHeight: {
                            minWidth: Breakpoints.Desktop,
                            value: 250,
                          },
                          width: [
                            { minWidth: Breakpoints.Desktop, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.video
                                  .postWidth,
                            },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Label 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 8 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Attachment Link :'}
              </Text>
              {/* Link */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {attachment_to_preview?.link}
              </Text>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(ApproveAdsScreen);
