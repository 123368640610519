import React from 'react';
import * as Lib from '../custom-files/Lib';

const ShowToastMessage = (type, header, message) => {
  if (!['success', 'error', 'info'].includes(type)) return;
  if (!message) return;

  Lib.Toast.show({
    type,
    text1: header || 'Montage',
    text2: message,
  });
};

export default ShowToastMessage;
